import React from "react";
import { connect } from "react-redux";
import { Redux, WebClient, constants, Overlay } from "../../../../components";
import { FormattedMessage, injectIntl } from "react-intl";
import assets from "../../../../components/utils/assets";
import process from "process/browser";
import TabItem from "./tab-item";
import Clock from "./clock";

const JackpotHeader = () => (
  <div className="jackpot-header">
    <div className="jackpot-container">
      <div className="jackpot-box daily">
        <div className="star">⭐</div>
        <div className="jackpot-title">Daily Jackpot</div>
        <div className="jackpot-amount">
          {/* <span className="jackpot-currency">₺</span> */}
          25,487
        </div>
      </div>

      <div className="jackpot-box weekly">
        <div className="star">⭐</div>
        <div className="jackpot-title">Weekly Jackpot</div>
        <div className="jackpot-amount">
          {/* <span className="jackpot-currency">₺</span> */}
          158,965
        </div>
      </div>
    </div>
  </div>
);

class MainHeader extends React.Component {
  render() {
    const { intl, member, activeScreen } = this.props;

    return (
      <div className="main-layout-top">
        <div className="tabs">
          <div className="tab-container">
            {member?.liveUser?.status == 1 && (
              <TabItem
                onClick={() => {
                  this.props.onChange("LiveMatches");
                }}
                title="lang_live_bets"
                className="live-matches-button"
                active={Boolean(activeScreen == "LiveMatches")}
              />
            )}

            {member?.sportUser?.status == 1 && (
              <TabItem
                onClick={() => {
                  this.props.onChange("Matches");
                }}
                title="lang_sport_bets"
                className="matches-button"
                active={Boolean(activeScreen == "Matches")}
              />
            )}

            {/* {member?.bingoUser?.status == 1 && (
              <TabItem
                onClick={() => {
                  this.props.onChange("LiveBingo");
                }}
                title="lang_live_bingo"
                className="live-bingo-button"
                active={Boolean(activeScreen == "LiveBingo")}
              />
            )} */}

            {member?.casinoUser?.status == 1 && (
              <TabItem
                onClick={() => {
                  this.props.onChange("LiveCasino");
                }}
                title="lang_live_casino"
                className="live-casino-button"
                active={Boolean(activeScreen == "LiveCasino")}
              />
            )}

            {member?.slotUser?.status == 1 && (
              <TabItem
                onClick={() => {
                  this.props.onChange("SlotGames");
                }}
                title="lang_slot_games"
                className="slot-games-button"
                active={Boolean(activeScreen == "SlotGames")}
              />
            )}

            {member?.virtualUser?.status == 1 && (
              <TabItem
                onClick={() => {
                  this.props.onChange("VirtualGames");
                }}
                title="lang_virtual_games"
                className="virtual-games-button"
                active={Boolean(activeScreen == "VirtualGames")}
              />
            )}
          </div>
        </div>

        <div className="seperator"></div>

        <div className="controls-cont">
          <div className="second-tab-item">
            <div className="second-tab-button">
              <span
                style={{
                  fontSize: "0.8vw",
                  lineHeight: "1vw",
                  fontWeight: "400",
                }}
                className="text"
              >
                <FormattedMessage id="lang_match_result" />
              </span>
            </div>
          </div>

          <div className="second-tab-item">
            <div
              onClick={() => {
                this.props.onLangClick();
              }}
              className="second-tab-button"
            >
              <span
                className="icon"
                style={{ backgroundImage: `url('${assets[intl.locale]}')` }}
              ></span>
              <span className="text">
                {
                  constants.languages.filter((x) => x.lang == intl.locale)[0]
                    .label
                }
              </span>
            </div>
          </div>

          <div className="second-tab-item">
            <div
              onClick={() => {
                this.props.onTicketCheckClick();
              }}
              className="second-tab-button"
            >
              <span className="text">
                <FormattedMessage
                  id="lang_ticket_check"
                  defaultMessage="lang_ticket_check"
                />
              </span>
            </div>
          </div>
        </div>

        <div className="seperator"></div>

        <div
          className="account-cont"
          style={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <div
            className="third-tab-item"
            style={{
              width:
                activeScreen === "SlotGames" || activeScreen === "LiveCasino"
                  ? "100%"
                  : "25%",
            }}
          >
            <div
              onClick={() => {
                this.props.onAdminLoginClick();
              }}
              className="third-tab-button"
            >
              <span>T{member.terminalNo}</span>

              <div className="font-sm text-white" style={{ fontSize: 11 }}>
                {process?.env.REACT_APP_VERSION}
              </div>
            </div>
          </div>
          <div
            className="third-tab-item"
            style={{
              width:
                activeScreen === "SlotGames" || activeScreen === "LiveCasino"
                  ? "100%"
                  : "25%",
            }}
          >
            <div
              onClick={() => {
                this.props.onPrintBalanceClick();
              }}
              className="third-tab-button"
            >
              <span>
                <FormattedMessage
                  id="lang_credit"
                  defaultMessage="lang_credit"
                />
              </span>
              <span>{member?.userCredit}</span>
            </div>
          </div>
        </div>
        {/* <JackpotHeader /> */}
      </div>
    );
  }
}

const msp = (state) => {
  return {
    config: state.config,
    member: state.member,
  };
};

export default connect(msp)(injectIntl(MainHeader));
